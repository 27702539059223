import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA PETERNAK------
    getPeternakAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/peternak/all", { params: credentials })
    },
    getPeternakSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/peternak/single", { params: credentials })
    },
    verifPeternak(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/peternak", credentials)
    },

    // -----API DATA FARM PETERNAK------
    getPeternakFarmAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/peternak/farm/all", { params: credentials })
    },
    getPeternakFarmSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/peternak/farm/single", { params: credentials })
    },
    verifPeternakFarm(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/peternak/farm", credentials)
    },
}